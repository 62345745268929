import { createContext, useEffect, useState } from "react";
import { ethers } from "ethers";
import ApeContractData from "../blockchain/ApeContract";
import Swal from "sweetalert2";
export const BlockchainContext = createContext();

const { ethereum } = window;

const getProvider = () => {
  return new ethers.providers.Web3Provider(ethereum);
};

const getSigner = () => {
  const provider = getProvider();
  return provider.getSigner();
};

// returns promise
const getSignerAddress = () => {
  const provider = getProvider();
  return provider.getSigner().getAddress();
};

const getCurrentNetwork = () => {
  const provider = getProvider();
  return provider.getNetwork();
};

// returns Promise
const getNetworkChainId = async () => {
  const network = await getCurrentNetwork();
  return network.chainId;
};

export const BlockchainContextProvider = (props) => {
  const [currentSigner, setCurrentSigner] = useState("");
  const [currentSignerAddress, setCurrentSignerAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState();
  const [phase, setPhase] = useState();
  const [IfWhitelisted, setIfWhitelisted] = useState();
  const [cost, setCost] = useState();
  const [totalSupply, setTotalSupply] = useState();

  useEffect(() => {
    checkIfWalletIsConnected();
    listenMMAccount(); // Event is registered in background
  }, []);

  async function listenMMAccount() {
    ethereum.on("accountsChanged", async function () {
      window.location.reload();
    });

    ethereum.on("chainChanged", (currentChainId) => {
      window.location.reload();
    });
  }

  const checkIfWalletIsConnected = async () => {
    try {
      if (!ethereum) return alert("Please install Metamask");

      const accounts = await ethereum.request({ method: "eth_accounts" });

      // Check Network
      const chainId = await getNetworkChainId();
      if (chainId !== 1) {
        alert("Please Change Network to Ethereum !");
        return;
      }

      if (accounts.length) {
        // Set Current Signer
        const signer = getSigner();
        setCurrentSigner(signer);

        // Set Current Signer Address
        const signerAddress = await getSignerAddress();
        setCurrentSignerAddress(signerAddress);

        await isWhitelisted(signerAddress);
        await getLimit(signerAddress);
        await getCost();
        await getTotalSupply();
        await getSalePhase();
      } else {
        console.log("No accounts found");
      }
    } catch (error) {
      alert(error.data.message);

      throw new Error("No Ethereum Object");
    }
  };

  const connectWallet = async () => {
    try {
      if (!ethereum) return alert("Please install Metamask");
      // Request Metamask for accounts
      await ethereum.request({ method: "eth_requestAccounts" });

      // Check Network
      const chainId = await getNetworkChainId();
      if (chainId !== 1) {
        alert("Please Change Network to Ethereum !");
        return;
      }

      // Set Current Signer
      const signer = getSigner();
      setCurrentSigner(signer);

      // Set Current Signer Address
      const signerAddress = await getSignerAddress();
      setCurrentSignerAddress(signerAddress);

      await isWhitelisted(signerAddress);
      await getLimit(signerAddress);
      await getCost();
      await getTotalSupply();
      await getSalePhase();
    } catch (error) {
      alert(error.data.message);

      throw new Error("No Ethereum Object");
    }
  };

  // Get APE Contract

  const getApeContract = async () => {
    console.log(currentSigner);
    if (!currentSigner) {
      console.log("Wallet not connected");
      // setIsLoading(false);
      return;
    }

    const provider = getProvider();
    const ApeContract = new ethers.Contract(
      ApeContractData.address,
      ApeContractData.abi,
      provider
    );
    return ApeContract;
  };

  /* 
  * -------------------------------------------
            Functions
  * -------------------------------------------
  */

  const mint = async (_mintAmount) => {
    // Get Ape Contract
    const apeContract = await getApeContract();

    try {
      // Set Stake
      const _cost = await apeContract.cost();
      const options = { value: _cost };
      let tx = await apeContract
        .connect(currentSigner)
        .mint(_mintAmount, options);
      await tx.wait();
    } catch (error) {
      show_error_alert(error);
    }
  };
  const preSaleMint = async (_mintAmount) => {
    // Get Ape Contract
    const apeContract = await getApeContract();

    try {
      // Set Stake
      const _cost = await apeContract.whiteListCost();
      const options = { value: _cost };
      let tx = await apeContract
        .connect(currentSigner)
        .whitelistMint(_mintAmount, options);
      await tx.wait();
    } catch (error) {
      show_error_alert(error);
    }
  };
  async function show_error_alert(error) {
    let temp_error = error.message.toString();
    console.log(temp_error);
    let error_list = [
      "You have not staked any NFTs",
      "Sent Amount Wrong",
      "No balance to claim",
      "No NFTs selected to stake",
      "No NFTs selected to unstake",
      "Max Supply Reached",
      "You have already Claimed Free Nft.",
      "Presale have not started yet.",
      "You are not in Presale List",
      "Staking Period is still not over",
      "Presale Ended.",
      "You are not Whitelisted.",
      "Sent Amount Not Enough",
      "Exceeds max tx per address",
      "Max 20 Allowed.",
      "insufficient funds",
      "Sale is Paused.",
      "mint at least one token",
      "Exceeds max tx per address",
      "Not enough tokens left",
      "incorrect ether amount",
      "The contract is paused!",
      "5 tokens per wallet allowed in presale",
      "10 tokens per wallet allowed in publicsale",
      "Invalid merkle proof",
      "Not enough tokens allowed in current phase",
      "Sold out!!!",
      "No more tokens left in current phase",
      "Wallet limit Reached",
      "Exceeds max NFT per wallet",
      "You are not whitelisted",
      "Exceeds max Nfts per wallet.",
      "Insufficient funds!",
      "Whitelist minting is Paused!",
      "Only 1022 NFTs are available for Presale",
    ];

    for (let i = 0; i < error_list.length; i++) {
      if (temp_error.includes(error_list[i])) {
        // set ("Transcation Failed")
        // alert(error_list[i]);
        console.log(error_list[i]);
        console.log("saadasd");
        Swal.fire({
          icon: "error",
          text: error_list[i],
        });
      }
    }
  }
  const isWhitelisted = async (signerAddress) => {
    // Get Ape Contract

    const provider = getProvider();
    const apeContract = new ethers.Contract(
      ApeContractData.address,
      ApeContractData.abi,
      provider
    );

    try {
      // Set Stake

      let checkIfWhitelisted = await apeContract.isWhitelisted(signerAddress);

      setIfWhitelisted(checkIfWhitelisted);
    } catch (error) {
      console.log(error);
    }
  };
  const getTotalSupply = async () => {
    // Get Ape Contract
    const provider = getProvider();
    const apeContract = new ethers.Contract(
      ApeContractData.address,
      ApeContractData.abi,
      provider
    );

    try {
      // Set Stake

      let _totalSupply = await apeContract.totalSupply();

      setTotalSupply(_totalSupply.toString());
    } catch (error) {
      console.log(error);
    }
  };

  const getSalePhase = async () => {
    // Get Ape Contract
    const provider = getProvider();
    const apeContract = new ethers.Contract(
      ApeContractData.address,
      ApeContractData.abi,
      provider
    );

    try {
      let presalePaused = await apeContract.WLpaused();
      let publicSalePaused = await apeContract.paused();
      if (presalePaused === true && publicSalePaused === true) {
        setPhase(1); //1 for sale not started
      } else if ((presalePaused === false) & (publicSalePaused === true)) {
        setPhase(2); // 2 for presale
      } else if ((presalePaused === true) & (publicSalePaused === false)) {
        setPhase(3); // 3 for publicsale
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getLimit = async (signerAddress) => {
    // Get Ape Contract
    const provider = getProvider();
    const apeContract = new ethers.Contract(
      ApeContractData.address,
      ApeContractData.abi,
      provider
    );

    try {
      let _limit = await apeContract.nftPerWLAddress(signerAddress);

      setLimit(_limit);
    } catch (error) {
      console.log(error);
    }
  };
  const getCost = async () => {
    // Get Ape Contract
    const provider = getProvider();
    const apeContract = new ethers.Contract(
      ApeContractData.address,
      ApeContractData.abi,
      provider
    );

    try {
      let presalePaused = await apeContract.WLpaused();

      let _cost;
      if (presalePaused === false) {
        _cost = await apeContract.whiteListCost();
        _cost = ethers.utils.formatEther(_cost);
      } else {
        _cost = await apeContract.cost();
        _cost = ethers.utils.formatEther(_cost);
      }
      setCost(_cost);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <BlockchainContext.Provider
      value={{
        currentSigner,
        currentSignerAddress,
        connectWallet,
        mint,
        preSaleMint,
        IfWhitelisted,
        cost,
        totalSupply,
        phase,
        limit,
      }}
    >
      {props.children}
    </BlockchainContext.Provider>
  );
};
