import React, { useState } from "react";
import "./style.css";
import logo from "../assets/Images/EFGC_Logo_White.png";
import ConnectWallet from "../components/connectWallet";
import ConnectedWallet from "../components/connectedWallet";
import Welocmewallet from "../components/welcomewallet/Index";
import Rejectwallet from "../components/rejectwallet/Index";

function Container() {
  const [panelId, setpanelId] = useState(1);

  const changePanelId = (key) => {
    setpanelId(key);
  };
  return (
    <div className="mainlogo">
      <img src={logo} alt="logo" className="imageoflogo" />

      {panelId === 1 && <ConnectWallet changePanelId={changePanelId} />}
      {panelId === 2 && <ConnectedWallet changePanelId={changePanelId} />}
      {panelId === 3 && <Welocmewallet changePanelId={changePanelId} />}
      {panelId === 4 && <Rejectwallet changePanelId={changePanelId} />}
    </div>
  );
}

export default Container;
