import React from "react";
import { useContext, useState, useEffect } from "react";
import { BlockchainContext } from "../../context/BlockchainContext";
import ApeContractData from "../../blockchain/ApeContract";
import "./style.css";

function ConnectedWallet(props) {
  const [loading, setloading] = useState(false);
  const [allowed, setAllowed] = useState(true);
  const [feedback, setFeedback] = useState("");
  const [feedback1, setFeedback1] = useState("");
  const { mint, preSaleMint, IfWhitelisted, cost, totalSupply, phase, limit } =
    useContext(BlockchainContext);
  console.log(totalSupply);
  console.log(cost);

  const changeScreen = () => {
    setloading(true);
    setTimeout(() => {
      props.changePanelId(3);
    }, "1000");
  };

  const mintHandler = async () => {
    setloading(true);
    setFeedback("Processing.....");
    setFeedback1("Secret Handshake");
    if (phase === 1) {
      setFeedback("Sale has not started yet");
    } else if (phase === 2) {
      if (IfWhitelisted === true) {
        if (limit === 1) {
          setFeedback("You cannot mint more than 1 ");
        } else {
          const tx = await preSaleMint(1);

          props.changePanelId(3);
        }
      } else {
        props.changePanelId(4);
      }
    } else if (phase === 3) {
      const tx = await mint(1);
      console.log(tx);

      props.changePanelId(3);
    }
  };
  return (
    <div className="mainconnected">
      <p className="nine22">{totalSupply}/1000</p>
      <p className="connectedtext">
        {ApeContractData.address &&
          `${ApeContractData.address.slice(
            0,
            6
          )}...${ApeContractData.address.slice(
            ApeContractData.address.length - 4,
            ApeContractData.address.length
          )}`}{" "}
        <br /> 1 WL
      </p>
      <p className="connectedtext22">
        {cost} eth
        <br />
        <p className="connectedtext44">excluding gas fees</p>
      </p>
      <div className="circcle22">
        {!loading ? (
          <a onClick={mintHandler}>
            <div className="circle">
              <p className="minttext">Mint</p>
            </div>
          </a>
        ) : (
          <p className="loadingtext">{feedback}</p>
        )}
      </div>
      <p className="connectedtext77">{feedback1}</p>
    </div>
  );
}

export default ConnectedWallet;
