import React from "react";
import { useContext, useState, useEffect } from "react";
import "./style.css";
import { BlockchainContext } from "../../context/BlockchainContext";

function ConnectWallet(props) {
  const { connectWallet, currentSignerAddress, IfWhitelisted } =
    useContext(BlockchainContext);
  const handleConnectWallet = async () => {
    await connectWallet();
    if (IfWhitelisted === true) {
      props.changePanelId(2);
    } else {
      props.changePanelId(4);
    }
  };
  useEffect(() => {
    connectWallet();
  }, []);
  return (
    <div className="connectwallteclass">
      <a onClick={handleConnectWallet}>
        <p className="connectWallettextclass">Connect Wallet</p>
      </a>
     
    </div>
  );
}

export default ConnectWallet;
