import React from "react";
import "./style.css";
import { useContext, useState, useEffect } from "react";
import { BlockchainContext } from "../../context/BlockchainContext";
import ApeContractData from "../../blockchain/ApeContract";
function Welocmewallet() {
  const { mint, preSaleMint, IfWhitelisted, cost, totalSupply, phase, limit } =
    useContext(BlockchainContext);
  return (
    <div className="mainwelcome22">
      <p className="weltext333">
        {" "}
        {ApeContractData.address &&
          `${ApeContractData.address.slice(
            0,
            6
          )}...${ApeContractData.address.slice(
            ApeContractData.address.length - 4,
            ApeContractData.address.length
          )}`}{" "}
      </p>
      <p className="weltext4444">Welcome </p>
      <p className="weltext55"> to the</p>
      <p className="weltext66"> EFFIGY CIRCLE </p>
      <p className="weltext5566"> Sincerely, </p>
      <p className="weltext5566"> -Nobody </p>
    </div>
  );
}

export default Welocmewallet;
